import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const ProjectContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  img.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    ${MEDIA.TABLET`
      position: static;
      padding-top: 80px;
    `}
  }
  .project {
    background: #fff;
    width: 100%;
    margin-top: 80vh;
    ${MEDIA.TABLET`
      margin-top: 0;
    `}
    &-content {
      max-width: 1440px;
      margin: 0 auto;
      padding: 16px;
      h1 {
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.4;
      }
    }
    &-info {
      display: flex;
      flex-wrap: wrap;
      padding: 16px 0;
      & > div {
        flex: 1 1;
      }
      table {
        font-size: 1.8rem;
        line-height: 1.4;

        th {
          text-align: right;
          padding: 4px 8px;
          font-weight: bold;
        }
      }
    }
    &-images {
      display: flex;
      padding: 32px 0;
      flex-wrap: wrap;
      div {
        width: 350px;
        margin: 16px;
        img {
          max-width: 100%;
        }
      }
    }
  }
`;
