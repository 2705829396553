import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
// import classNames from 'classnames';
import Layout from 'components/layout';
import Head from 'components/head';
// import Box from 'components/box';
// import PageBlocks from 'components/pageBlocks';
import { ProjectContainer } from './project.css';

const ProjectTemplate = ({ data }) => {
  const fields = JSON.parse(data.items.field);
  return (
    <Layout locale="nl">
      <Head pageTitle={fields.title} locale="nl" />
      <ProjectContainer>
        <img
          className="header"
          src={
            'https://cms.burobork.nl/images/' +
            fields.fields.Hoofdafbeelding[0].reference
          }
          alt={fields.title}
        />
        <div className="project">
          <div className="project-content">
            <h1>{fields.title}</h1>
            <div className="project-info">
              <div
                dangerouslySetInnerHTML={{
                  __html: fields.fields['Beschrijving']
                    ? fields.fields['Beschrijving']['nl']
                    : null,
                }}
              />
              <div>
                <table>
                  <tbody>
                    <tr>
                      <th>Opdracht:</th>
                      <td>{fields.fields.Opdracht}</td>
                    </tr>
                    <tr>
                      <th>Locatie:</th>
                      <td>{fields.fields.Locatie}</td>
                    </tr>
                    <tr>
                      <th>Ontwerp:</th>
                      <td>{fields.fields.Ontwerp}</td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>{fields.fields.Status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="project-images">
              {fields.fields.Afbeeldingen.map(image => (
                <div key={image.reference}>
                  <img
                    src={'https://cms.burobork.nl/images/' + image.reference}
                    alt={image.title}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </ProjectContainer>
    </Layout>
  );
};

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query ProjectPage($id: String!) {
    items(id: { eq: $id }) {
      id
      field
    }
  }
`;

export default ProjectTemplate;
